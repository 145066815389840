import Tommy from "../../img/home/Tommy.jpg";
import A from "../../img/portfolio/1.png";
import B from "../../img/portfolio/2.png";
import C from "../../img/portfolio/3.png";
import D from "../../img/portfolio/4.png";

import Project_1_Shot_1 from "../../img/portfolio/project1/project_1_shot_1.jpg";
import Project_1_Shot_2 from "../../img/portfolio/project1/project_1_shot_2.jpg";
import Project_1_Shot_3 from "../../img/portfolio/project1/project_1_shot_3.jpg";
import Project_1_Shot_4 from "../../img/portfolio/project1/project_1_shot_4.jpg";
import Project_1_Shot_5 from "../../img/portfolio/project1/project_1_shot_5.jpg";
import Project_1_Shot_6 from "../../img/portfolio/project1/project_1_shot_6.jpg";
import Project_1_Shot_7 from "../../img/portfolio/project1/project_1_shot_7.jpg";
import Project_1_Shot_8 from "../../img/portfolio/project1/project_1_shot_8.jpg";
import Project_1_Shot_9 from "../../img/portfolio/project1/project_1_shot_9.jpg";

import Project_2_Shot_1 from "../../img/portfolio/project2/project_2_shot_1.jpg";
import Project_2_Shot_2 from "../../img/portfolio/project2/project_2_shot_2.jpg";
import Project_2_Shot_3 from "../../img/portfolio/project2/project_2_shot_3.jpg";
import Project_2_Shot_4 from "../../img/portfolio/project2/project_2_shot_4.jpg";
import Project_2_Shot_5 from "../../img/portfolio/project2/project_2_shot_5.jpg";
import Project_2_Shot_6 from "../../img/portfolio/project2/project_2_shot_6.jpg";
import Project_2_Shot_7 from "../../img/portfolio/project2/project_2_shot_7.jpg";
import Project_2_Video_Shot_1 from "../../img/portfolio/project2/video_shot_1.mp4";

import Project_3_Video_Shot_1 from "../../img/portfolio/project3/video_shot_1.mp4";
import Project_3_Shot_1 from "../../img/portfolio/project3/IncelldermNS-3.jpg";
import Project_3_Shot_2 from "../../img/portfolio/project3/DaiNamMenu-2.jpg";
import Project_3_Shot_3 from "../../img/portfolio/project3/DaiNamMenu-93.jpg";
import Project_3_Shot_4 from "../../img/portfolio/project3/Boxing-gym.jpg";
import Project_3_Video_Shot_2 from "../../img/portfolio/project3/video_shot_2.mp4";

import Portfolio_Shot_1 from "../../img/portfolio/portfolio_shot_1_resized.jpg"
import Portfolio_Shot_2 from "../../img/portfolio/portfolio_shot_2_resized.jpg"
import Portfolio_Shot_3 from "../../img/portfolio/portfolio_shot_3_resized.jpg"
import Portfolio_Shot_4 from "../../img/portfolio/portfolio_shot_4_resized.jpg"

export const data = [
  { src: Tommy },
  { src: A },
  { src: B },
  { src: C },
  { src: D },
  { src: "https://images.unsplash.com/photo-1682685796186-1bb4a5655653" },
  { src: "https://images.unsplash.com/photo-1683965789236-5e83c8adb5cf" },
  { src: "https://images.unsplash.com/photo-1683914791767-764d03299719" },
  { src: "https://images.unsplash.com/photo-1684093024940-7847c7af3faa" },
  { src: "https://images.unsplash.com/photo-1684090110679-2f3dde062b9e" },
  { src: "https://images.unsplash.com/photo-1684093025993-dcb8dec5625e" },
  { src: "https://images.unsplash.com/photo-1661956602868-6ae368943878" },
  { src: "https://plus.unsplash.com/premium_photo-1671485196355-32005a27fd02" },
  { src: "https://images.unsplash.com/photo-1683973122992-dcdf1569523d" },
  { src: "https://images.unsplash.com/photo-1684072108336-40bb8228888e" },
  { src: "https://images.unsplash.com/photo-1682685796186-1bb4a5655653" },
  { src: "https://images.unsplash.com/photo-1683965789236-5e83c8adb5cf" },
  { src: "https://images.unsplash.com/photo-1683914791767-764d03299719" },
  { src: "https://images.unsplash.com/photo-1684093024940-7847c7af3faa" },
  { src: "https://images.unsplash.com/photo-1684090110679-2f3dde062b9e" },
  { src: "https://images.unsplash.com/photo-1684093025993-dcb8dec5625e" },
  { src: "https://images.unsplash.com/photo-1661956602868-6ae368943878" },
  { src: "https://plus.unsplash.com/premium_photo-1671485196355-32005a27fd02" },
  { src: "https://images.unsplash.com/photo-1683973122992-dcdf1569523d" },
  { src: "https://images.unsplash.com/photo-1684072108336-40bb8228888e" },
  { src: "https://images.unsplash.com/photo-1682685796186-1bb4a5655653" },
  { src: "https://images.unsplash.com/photo-1683965789236-5e83c8adb5cf" },
  { src: "https://images.unsplash.com/photo-1683914791767-764d03299719" },
  { src: "https://images.unsplash.com/photo-1684093024940-7847c7af3faa" },
  { src: "https://images.unsplash.com/photo-1684090110679-2f3dde062b9e" },
  { src: "https://images.unsplash.com/photo-1684093025993-dcb8dec5625e" },
  { src: "https://images.unsplash.com/photo-1661956602868-6ae368943878" },
  { src: "https://plus.unsplash.com/premium_photo-1671485196355-32005a27fd02" },
  { src: "https://images.unsplash.com/photo-1683973122992-dcdf1569523d" },
  { src: "https://images.unsplash.com/photo-1683973122992-dcdf1569523d" },
  { src: "https://images.unsplash.com/photo-1684072108336-40bb8228888e" },
  { src: "https://images.unsplash.com/photo-1682685796186-1bb4a5655653" },
  { src: "https://images.unsplash.com/photo-1683965789236-5e83c8adb5cf" },
  { src: "https://images.unsplash.com/photo-1683914791767-764d03299719" },
  { src: "https://images.unsplash.com/photo-1684093024940-7847c7af3faa" },
  { src: "https://images.unsplash.com/photo-1684090110679-2f3dde062b9e" },
  { src: "https://images.unsplash.com/photo-1684093025993-dcb8dec5625e" },
  { src: "https://images.unsplash.com/photo-1661956602868-6ae368943878" },
  { src: "https://plus.unsplash.com/premium_photo-1671485196355-32005a27fd02" },
  { src: "https://images.unsplash.com/photo-1683973122992-dcdf1569523d" },
  { src: "https://images.unsplash.com/photo-1683973122992-dcdf1569523d" },
];

export const PortfolioSourceImg = {
  SHOT_1: Portfolio_Shot_1,

  SHOT_2: Portfolio_Shot_2,

  SHOT_3: Portfolio_Shot_3,
  
  SHOT_4: Portfolio_Shot_4,
};

export const Project1SourceImg = {
  SHOT_1: Project_1_Shot_1,

  SHOT_2: Project_1_Shot_2,

  SHOT_3: Project_1_Shot_3,

  SHOT_4: Project_1_Shot_4,

  SHOT_5: Project_1_Shot_5,

  SHOT_6: Project_1_Shot_6,

  SHOT_7: Project_1_Shot_7,

  SHOT_8: Project_1_Shot_8,

  SHOT_9: Project_1_Shot_9,

};

export const Project2SourceImg = {
  SHOT_1: Project_2_Shot_1,

  SHOT_2: Project_2_Shot_2,

  SHOT_3: Project_2_Shot_3,

  SHOT_4: Project_2_Shot_4,

  SHOT_5: Project_2_Shot_5,

  SHOT_6: Project_2_Shot_6,

  SHOT_7: Project_2_Shot_7,

  SHOT_8: Project_2_Video_Shot_1,
};

export const Project3SourceImg = {
  SHOT_1: Project_3_Video_Shot_1,

  SHOT_2: Project_3_Shot_1,

  SHOT_3: Project_3_Shot_2,

  SHOT_4: Project_3_Shot_3,

  SHOT_5: Project_3_Shot_4,

  SHOT_6: Project_3_Video_Shot_2,
};
